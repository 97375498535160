import { ArrowDownOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import { Rule } from 'digicust_types';
import ConditionBlock from './Components/ConditionBlock';
import ThenBlockCard from './Components/ThenBlockCard';

const RuleComponent = ({
  value,
  onValueChange,
}: {
  value: Rule;
  onValueChange?: (value: Rule) => void;
}) => {
  return (
    <Space
      direction="vertical"
      style={{
        width: '100%',
        overflow: 'auto',
        paddingRight: '16px',
        paddingBottom: '32px',
        height: 'calc(100vh - 120px)',
      }}
    >
      <Typography.Title
        level={5}
        style={{ margin: 25 }}
        editable={
          onValueChange
            ? {
                autoSize: false,
                onChange: (name) => {
                  onValueChange({ ...value, name });
                },
              }
            : false
        }
      >
        {value?.name}
      </Typography.Title>
      <ConditionBlock
        value={value}
        onChange={onValueChange ? onValueChange : () => null}
      />

      <ArrowDownOutlined style={{ marginLeft: 20 }} />

      <ThenBlockCard
        value={value}
        onChange={onValueChange ? onValueChange : () => null}
      />
    </Space>
  );
};

export default RuleComponent;
