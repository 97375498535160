import {
  Button,
  Card,
  Flex,
  Popover,
  Space,
  TreeSelect,
  Typography,
} from 'antd';
import {
  AggregatedCaseDataModel,
  CompanyModel,
  TariffSystem,
} from 'digicust_types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDataContext } from '../AppDataProvider';
import CustomsOffices from './general-tab/CustomsOffices';
import { useGeneralDetailsFields } from './general-tab/GeneralDetailsInput';
import PreliminaryDocuments from './general-tab/PreliminaryDocuments';
import ContainersInput from './shipment-tab/ContainersInput';
import { useFreightDocumentMapper } from './shipment-tab/FreightDocumentInput';
import MeansOfTransportation from './shipment-tab/MeansOfTransportationInput';
import PackagesInput from './shipment-tab/PackagesInput';
import SealsInput from './shipment-tab/SealsInput';
import CompanyDetailsInput from './stakeholder-tab/CompanyDetailsInput';
import { useStakeholderFields } from './stakeholder-tab/StakeholderDocumentInput';
import { CaseFieldMapper } from './types';

type TreeNode = {
  title: string;
  value: string;
  children?: TreeNode[];
} & Partial<CaseFieldMapper>;

export function useCaseFieldMapper({
  caseData,
  updateItem,
}: {
  caseData: AggregatedCaseDataModel;
  updateItem: (caseData: AggregatedCaseDataModel) => void;
}) {
  const { t } = useTranslation();
  const { projectDetails } = useAppDataContext();

  const { fields: generalFields } = useGeneralDetailsFields({
    caseData,
    updateItem,
    tariffSystem: projectDetails?.tariffNumberTreeSystem || TariffSystem.DE,
  });

  const { fields: fdFields } = useFreightDocumentMapper({
    caseData,
    updateItem,
  });

  const { companies } = useStakeholderFields();

  const caseFieldsTree: TreeNode[] = [
    {
      title: 'General',
      value: 'general',
      children: generalFields.map((cur) => ({ ...cur, value: cur.title })),
    },
    {
      title: 'Freight Documents',
      value: 'Freight Documents',
      children: fdFields.map((cur) => ({ ...cur, value: cur.title })),
    },
    {
      title: 'Stakeholder',
      value: 'Stakeholder',
      children: companies.map((com) => ({
        title: com.title,
        value: com.title,
        getValue: () => (caseData as any)?.[com.property],
        updateProperty: (company: CompanyModel) => {
          updateItem({ ...(caseData || {}), [com.property]: company });
        },
        renderComponent: (
          value: CompanyModel,
          updateProperty: (company: CompanyModel) => void,
        ) => (
          <CompanyDetailsInput
            value={value}
            keyValue={com.title}
            onChange={(comp) => {
              updateProperty(comp);
            }}
          />
        ),
      })),
    },
    {
      title: 'Others',
      value: 'Others',
      children: [
        {
          title: t('Custom Offices'),
          value: t('Custom Offices'),
          hideTitle: true,
          vertical: true,
          getValue: () => caseData?.customsOffices,
          updateProperty: (customsOffices) => {
            updateItem({ ...(caseData || {}), customsOffices });
          },
          renderComponent: (value, updateProperty) => (
            <CustomsOffices
              value={value}
              onValueChange={(offices) => {
                updateProperty(offices);
              }}
            />
          ),
        },
        {
          title: t('Preliminary Documents'),
          value: t('Preliminary Documents'),
          hideTitle: true,
          vertical: true,
          getValue: () => caseData?.preliminaryDocuments,
          updateProperty: (preliminaryDocuments) => {
            updateItem({ ...(caseData || {}), preliminaryDocuments });
          },
          renderComponent: (value, updateProperty) => (
            <PreliminaryDocuments
              value={value}
              onValueChange={(documents) => {
                updateProperty(documents);
              }}
            />
          ),
        },
        {
          title: t('Means Of Transportation'),
          value: t('Means Of Transportation'),
          hideTitle: true,
          vertical: true,
          getValue: () => caseData?.meansOfTransportation,
          updateProperty: (meansOfTransportation) => {
            updateItem({ ...(caseData || {}), meansOfTransportation });
          },
          renderComponent: (value, updateProperty) => (
            <MeansOfTransportation
              value={value}
              onValueChange={(meansOfTransportation) => {
                updateProperty(meansOfTransportation);
              }}
            />
          ),
        },
        {
          title: t('Packages'),
          value: t('Packages'),
          hideTitle: true,
          vertical: true,
          getValue: () => caseData?.packages,
          updateProperty: (packages) => {
            updateItem({ ...(caseData || {}), packages });
          },
          renderComponent: (value, updateProperty) => (
            <PackagesInput
              value={value}
              onValueChange={(packageItem) => {
                updateProperty(packageItem);
              }}
            />
          ),
        },
        {
          title: t('Containers'),
          value: t('Containers'),
          hideTitle: true,
          vertical: true,
          getValue: () => caseData?.containers,
          updateProperty: (containers) => {
            updateItem({ ...(caseData || {}), containers });
          },
          renderComponent: (value, updateProperty) => (
            <ContainersInput
              value={value}
              onValueChange={(containerItem) => {
                updateProperty(containerItem);
              }}
            />
          ),
        },
        {
          title: t('Seals'),
          value: t('Seals'),
          hideTitle: true,
          vertical: true,
          getValue: () => caseData?.seals,
          updateProperty: (seals) => {
            updateItem({ ...(caseData || {}), seals });
          },
          renderComponent: (value, updateProperty) => (
            <SealsInput
              value={value}
              onValueChange={(seals) => {
                updateProperty(seals);
              }}
            />
          ),
        },
      ],
    },
  ];

  return {
    caseFieldsTree,
  };
}

export default function SearchableCaseFields({
  caseData,
  updateItem,
}: {
  caseData: AggregatedCaseDataModel;
  updateItem: (caseData: AggregatedCaseDataModel) => void;
}) {
  const { t } = useTranslation();
  const { caseFieldsTree } = useCaseFieldMapper({
    caseData,
    updateItem,
  });

  const [search, setSearch] = useState('');
  return (
    <Space direction="vertical" style={{ width: '100%', marginBottom: 24 }}>
      <Space size={0} direction="vertical" style={{ width: '100%' }}>
        <Typography.Text>{t('Search Case Details Properties')}</Typography.Text>

        <TreeSelect
          placeholder={t('Select Execution Strategy Properties')}
          style={{ width: '100%' }}
          treeData={caseFieldsTree}
          showSearch
          size="large"
          treeDefaultExpandAll
          onChange={(val) => setSearch(val)}
          filterTreeNode={(inputValue, treeNode) =>
            JSON.stringify(treeNode.title)
              ?.toLowerCase()
              .includes(inputValue?.toLowerCase())
          }
          allowClear
        />
      </Space>

      <Space
        direction="vertical"
        style={{ maxHeight: '400px', width: '100%', overflow: 'auto' }}
      >
        {caseFieldsTree
          .flatMap((cur) =>
            cur.children?.flatMap((child) => child.children || child),
          )
          .filter((cur: any) =>
            search
              ? cur.title
                  ?.toLowerCase()
                  .includes((search || 'placeholder')?.toLowerCase())
              : cur.getValue() ||
                cur.title
                  ?.toLowerCase()
                  .includes((search || 'placeholder')?.toLowerCase()),
          )
          .map((field: any) => {
            return (
              <Card
                style={{
                  borderColor: field.title
                    .toLowerCase()
                    .includes(search || 'placeholder')
                    ? '#1677ff'
                    : '',
                }}
              >
                <Flex vertical={field.vertical} justify="space-between" gap={5}>
                  {!field.hideTitle && (
                    <Popover placement="right" content={field.help}>
                      <Typography.Text style={{ fontWeight: 600 }}>
                        {field.title}
                      </Typography.Text>
                    </Popover>
                  )}

                  {!field.getValue() && (
                    <div style={{ flex: 1, textAlign: 'end' }}>
                      <Space.Compact
                        direction="horizontal"
                        style={{ height: '100%' }}
                      >
                        {field.suggestions?.map((suggestion: any) => (
                          <Button
                            type="dashed"
                            size="small"
                            tabIndex={-1}
                            onClick={() => {
                              field.updateProperty(suggestion?.value);
                            }}
                          >
                            {suggestion?.title}
                          </Button>
                        ))}
                      </Space.Compact>
                    </div>
                  )}

                  <div style={{ maxWidth: '70%', minWidth: '40%' }}>
                    {field.renderComponent(
                      field.getValue(),
                      field.updateProperty,
                    )}
                  </div>
                </Flex>
              </Card>
            );
          })}
      </Space>
    </Space>
  );
}
