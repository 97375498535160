import { ArrowDownOutlined } from '@ant-design/icons';
import { Divider, Space } from 'antd';
import { ExecutionStrategy } from 'digicust_types';
import { DataExtractionCard } from './DataExtractionCard';
import { DataIntegrationCard } from './DataIntegrationCard';
import { DataValidationCard } from './DataValidationCard';
import { UploadCard } from './UploadCard';
import { DataNormalizationCard } from './data-normalization';
import { SearchableExecutionStrategy } from './execution-strategy-field-mapper';

export default function ProcessingTab({
  value,
  onChange,
  minimalMode,
}: {
  value: ExecutionStrategy;
  onChange: (data: ExecutionStrategy) => void;
  minimalMode?: boolean;
}) {
  return (
    <div
      style={
        minimalMode
          ? {}
          : {
              display: 'flex',
              width: '100%',
              gap: 30,
              padding: '0 16px',
              margin: 30,
            }
      }
    >
      <Space
        direction="vertical"
        style={{
          width: '100%',
          maxWidth: '1000px',
          maxHeight: 'calc(100vh - 160px)',
          overflowY: 'auto',
          paddingRight: '16px',
          paddingBottom: 100,
        }}
      >
        <SearchableExecutionStrategy
          execStrategy={value}
          onValueChange={onChange}
        />

        <Divider />

        <UploadCard execStrategy={value} onValueChange={onChange} />
        <ArrowDownOutlined style={{ marginLeft: '80px' }} />

        <DataExtractionCard execStrategy={value} onValueChange={onChange} />
        <ArrowDownOutlined style={{ marginLeft: '80px' }} />

        <DataNormalizationCard execStrategy={value} onValueChange={onChange} />
        <ArrowDownOutlined style={{ marginLeft: '80px' }} />

        <DataValidationCard value={value} onValueChange={onChange} />
        <ArrowDownOutlined style={{ marginLeft: '80px' }} />

        <DataIntegrationCard execStrategy={value} onValueChange={onChange} />
      </Space>
    </div>
  );
}
