import { SwapOutlined } from '@ant-design/icons';
import { Button, Card, Radio, Space, Typography } from 'antd';
import { Rule } from 'digicust_types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CaseDetailsSearchable from '../../../../CustomsCaseDetails/case-details-searchable-mapper';
import EditInformationModal from '../../../../CustomsCaseDetails/EditInformationModal';

export default function ThenBlockCard({
  value,
  onChange,
}: {
  value: Rule;
  onChange: (value: Rule) => void;
}) {
  const { t } = useTranslation();

  const [openStatus, setOpenStatus] = useState(false);

  return (
    <Card>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Typography.Title level={5} style={{ margin: 0, whiteSpace: 'nowrap' }}>
          {t('Then')}
        </Typography.Title>

        <Space>
          <Radio.Group
            onChange={(e) => {
              onChange({
                ...value,
                userInput: {
                  ...(value?.userInput || {}),
                  priority: e.target.value,
                },
              });
            }}
            value={value?.userInput?.priority}
          >
            <Radio.Button value="beforeNormalization">
              {t('Fallback To')}
            </Radio.Button>
            <Radio.Button value="afterNormalization">
              {t('Overwrite and Merge')}
            </Radio.Button>
            <Radio.Button value="replace">{t('Replace')}</Radio.Button>
          </Radio.Group>

          <Button
            onClick={() => setOpenStatus(true)}
            icon={<SwapOutlined />}
            size="large"
          >
            {t('Edit Information')}
          </Button>

          <EditInformationModal
            value={value}
            openStatus={openStatus}
            onCancel={() => setOpenStatus(false)}
            onChange={onChange}
          />
        </Space>

        <CaseDetailsSearchable
          userInput={value?.userInput || {}}
          onChange={(userInput) =>
            onChange({
              ...(value || {}),
              userInput: {
                ...(value?.userInput || {}),
                ...userInput,
              },
            })
          }
        />
      </Space>
    </Card>
  );
}
